<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body class="d-flex justify-content-between align-items-center">
        <div class="h4">
          การติดตามลูกค้า
        </div>

        <div
          class="flex"
          style="gap: .3125rem;"
        >
          <button
            class="btn-taper"
            :class="[isActive == 999 ? 'btn-taper_acctive' : '']"
            @click="getDate(999) , getdata()"
          >
            ยังไม่ฝากทั้งหมด
          </button>

          <button
            class="btn-taper"
            :class="[isActive == 1 ? 'btn-taper_acctive' : '']"
            @click="getDate(1) , getdata()"
          >
            ไม่ได้ฝาก 1 วัน
          </button>

          <button
            class="btn-taper"
            :class="[isActive == 3 ? 'btn-taper_acctive' : '']"
            @click="getDate(3) , getdata()"
          >
            ไม่ได้ฝาก 3 วัน
          </button>

          <button
            class="btn-taper"
            :class="[isActive == 7 ? 'btn-taper_acctive' : '']"
            @click="getDate(7) , getdata()"
          >
            ไม่ได้ฝาก 7 วัน
          </button>

          <button
            class="btn-taper"
            :class="[isActive == 0 ? 'btn-taper_acctive' : '']"
            @click="getDate(0) , getdata()"
          >
            ที่โทรแล้ว
          </button>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(fullname)="data">
          <div class="text-center">
            <small>
              {{ data.item.name + ' ' + data.item.surname }}
            </small>
          </div>
        </template>

        <template #cell(depchecks)="data">
          <div class="text-center">
            <small>
              {{ depcheck(data.item) }}
            </small>
          </div>
        </template>

        <template #cell(dep_amounts)="data">
          <div class="text-center">
            <small>
              {{ dep_amount(data.item) }}
            </small>
          </div>
        </template>

        <template #cell(call_date)="data">
          <div
            v-if="!data.item.call_1st"
            class="text-center"
          >
            <b-form-checkbox
              v-model="data.item.call_1sts"
              name="check-button"
              switch
              @input="actions(data.item.username)"
            />
          </div>
          <div
            v-if="data.item.call_1st"
            class="text-center"
          >
            <small>
              {{ data.item.call_1st }}
            </small>
          </div>
        </template>

        <template #cell(call_date_2)="data">
          <div
            v-if="data.item.call_1st && !data.item.call_2nd"
            class="text-center"
          >
            <b-form-checkbox
              v-model="data.item.call_2nds"
              name="check-button"
              switch
              @input="actions(data.item.username)"
            />
          </div>
          <div
            v-if="data.item.call_2nd"
            class="text-center"
          >
            <small>
              {{ data.item.call_2nd }}
            </small>
          </div>
        </template>

        <template #cell(status)="data">
          <b-form-select
            v-model="data.item.call_status222"
            size="sm"
            inline
            :options="statusOption"
            @change="addstatus(data.item.username , data.item.call_status222)"
          />
        </template>
        <template #cell(call_status)="data">
          <b-badge
            v-if="data.item.call_status === 'รับสาย'"
            pill
            variant="success"
          >
            {{ data.item.call_status }}
          </b-badge>
          <b-badge
            v-if="data.item.call_status === 'ไม่รับสาย'"
            pill
            variant="danger"
          >
            {{ data.item.call_status }}
          </b-badge>
          <b-badge
            v-if="data.item.call_status === 'ไม่ว่างคุยสาย'"
            pill
            variant="warning"
          >
            {{ data.item.call_status }}
          </b-badge>
          <b-badge
            v-if="data.item.call_status === 'ตัดสายทิ้ง'"
            pill
            variant="danger"
          >
            {{ data.item.call_status }}
          </b-badge>
          <b-badge
            v-if="data.item.call_status === 'ปิดเครื่อง/ติดต่อไม่ได้'"
            pill
            variant="danger"
          >
            {{ data.item.call_status }}
          </b-badge>
          <b-badge
            v-if="data.item.call_status === 'เบอร์ผิด'"
            pill
            variant=""
          >
            {{ data.item.call_status }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getdata()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        id="modal-1"
        title="เติมเครดิต"
        ok-title="เติมเครดิต"
      >
        <b-form-group>
          <v-select
            v-model="member"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username"
            :options="optionsMember"
          />
        </b-form-group>
        <b-form-group
          label="จำนวนเงินที่ฝาก"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <!-- <feather-icon icon="CheckSquareIcon" /> -->
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="amount"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BBadge, BFormCheckbox, BInputGroupPrepend, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BCardBody, VBToggle, BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 100,
      pageOptions: [100, 200, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'tel', label: 'เบอร์โทร' },
        { key: 'fullname', label: 'ชื่อ - นามสกุล' },
        {
          key: 'created_at',
          label: 'วันที่สมัคร',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm'),
        },
        { key: 'call_date', label: 'เวลา / วันที่โทร' },
        { key: 'call_status', label: 'สถานะ' },
        { key: 'call_1st_byadmin', label: 'คนโทร' },
        { key: 'call_date_2', label: 'เวลา / วันที่โทร (รอบ 2)' },
        { key: 'call_status2', label: 'สถานะ (รอบ 2)' },
        { key: 'call_2nd_byadmin', label: 'คนโทร (รอบ 2)' },
        { key: 'depchecks', label: 'ฝาก/ไม่ฝาก' },
        { key: 'dep_amounts', label: 'ฝาก' },
        { key: 'status', label: 'สถานะ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],

      addNewDataSidebar: false,
      statusOption: [
        { value: null, text: 'เลือกสถานะ', class: 'bg-primary' },
        { value: 'รับสาย', text: 'รับสาย', class: 'bg-danger' },
        { value: 'ไม่รับสาย', text: 'ไม่รับสาย', class: 'bg-danger' },
        { value: 'ไม่ว่างคุยสาย', text: 'ไม่ว่างคุยสาย', class: '' },
        { value: 'ตัดสายทิ้ง', text: 'ตัดสายทิ้ง', class: '' },
        { value: 'ปิดเครื่อง/ติดต่อไม่ได้', text: 'ปิดเครื่อง/ติดต่อไม่ได้', class: '' },
        { value: 'เบอร์ผิด', text: 'เบอร์ผิด', class: '' },
      ],
      status: null,
      call: false,
      call2: false,
      isActive: 999,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // this.interval = setInterval(() => {
    //   this.getSMS()
    // }, 30000)
  },
  beforeDestroy() {
    // clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    // this.Get_userlist()
    this.getdata()
  },
  methods: {
    depcheck(item) {
      const check = item.call_2nd ? item.call_2nd : item.call_1st
      // console.log(check)
      // eslint-disable-next-line no-self-compare
      if (!check) {
        return 'ยังไม่ฝาก'
      } if (item.last_dep_at < check) {
        return 'ยังไม่ฝาก'
      }
      return 'ฝากมาแล้ว'
    },
    dep_amount(item) {
      const check = item.call_2nd ? item.call_2nd : item.call_1st
      // console.log(check)
      // eslint-disable-next-line no-self-compare
      if (!check) {
        return ''
      } if (item.last_dep_at < check) {
        return ''
      }
      return item.deplast_amount
    },
    getDate(val) {
      this.isActive = val
    },
    actions(username) {
      const obj = {
        username,
      }
      this.$http
        .post('/calling/action', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getdata()
          this.Success('ทำรายการสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    addstatus(username, status) {
      const obj = {
        username,
        status,
      }
      // console.log(obj)
      this.$http
        .post('/calling/status', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getdata()
          this.Success('ทำรายการสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    getdata() {
      this.show = true
      const obj = {
        perPage: this.perPage,
        page: this.currentPage,
        date: this.isActive,
      }
      this.$http
        .post('/calling/user', obj)
        .then(response => {
          this.show = false

          console.log(response.data)
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
th {
  text-wrap: nowrap !important;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.btn-taper {
  height: 2.1875rem;
  padding: 0 30px;
  margin: 0 2px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  color: #3d195b;
  background-color: #eeedfd;
}

.btn-taper_acctive {
  color: #fff;
  background-color: #3d195b;
}
</style>
